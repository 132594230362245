import React from 'react';
import { PanelPageProps } from '../types';

import ResultGap from './resultGap';
import ViewSnapshot from './viewSnapshot';
import Tabs from './tabs';

const MainPanel: React.FC<PanelPageProps> = (props) => {
  return (
    <div>
      <Tabs
        defaultActive={1}
        tabs={[
          { label: 'Result Gap', component: <ResultGap /> },
          { label: 'View Snapshot', component: <ViewSnapshot {...props} /> },
        ]}
      />
    </div>
  );
};

export default MainPanel;
