export const appendPercent = (percent: any) => {
  let result = '';
  const percentFloat = parseFloat(percent);

  if (!isNaN(percentFloat)) {
    result = `${percentFloat}%`;
  }

  return result;
};
