import React, { Fragment, useMemo, useState } from 'react';
import { useStyles2, Text, Button, EmptySearchResult } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { locationService } from '@grafana/runtime';

import { PanelPageProps, IViewSnapshotItem } from '../../types';
import { convertToDisplayDateTimeTH } from '../../utils/dateTime';
import { appendPercent } from '../../utils/string';
import Item from './item';
import Detail from './detail';

interface IViewSnapshotProps extends PanelPageProps {}

const ViewSnapshot: React.FC<IViewSnapshotProps> = ({ data, height, width }) => {
  const [viewSnapshotIndex, setViewSnapshotIndex] = useState(0);
  const [isOpenDetail, setOpenDetail] = useState(false);
  const style = useStyles2(getStyles);

  const viewSnapshotList = useMemo(() => {
    const result: IViewSnapshotItem[] = [];
    data?.series?.forEach((series) => {
      if (series?.refId === 'view_snapshot_data') {
        const labels = series?.fields?.[1]?.labels;
        const outofstockRate = series?.fields?.[1]?.values?.[0] || '';
        if (labels) {
          result.push({
            id: labels?.shelfName,
            shelfName: labels?.shelfName,
            outOfStock: outofstockRate,
            outOfStockDisplay: appendPercent(outofstockRate),
            image: labels?.shelfPicture,
            storeID: labels?.storeID,
            updateTime: labels?.updateTime,
            updateTimeDisplay: convertToDisplayDateTimeTH(labels?.updateTime),
          });
        }
      }
    });

    return result;
  }, [data]);

  const viewSnapshot = useMemo(() => {
    return viewSnapshotList?.[viewSnapshotIndex];
  }, [viewSnapshotList, viewSnapshotIndex]);

  const onClickSnapshotItem = (index: number) => {
    setViewSnapshotIndex(index);
  };

  const onClickDetail = () => {
    locationService.partial(
      {
        'var-store_id': viewSnapshot?.storeID,
        'var-mod_no': viewSnapshot?.id,
        'var-update_time': viewSnapshot?.updateTime,
      },
      true
    );
    setOpenDetail(true);
  };

  const onClickCloseDetail = () => {
    locationService.partial(
      {
        'var-store_id': null,
        'var-mod_no': null,
        'var-update_time': null,
      },
      true
    );
    setOpenDetail(false);
  };

  return (
    <div className={style.container}>
      {/* view snapshot list */}
      <div className={style.viewSnapshotListBox}>
        {viewSnapshotList?.length ? (
          <Fragment>
            <div className={style.viewSnapshotListTitleBox}>
              <Text element="h5">Available</Text>
              <Text element="span" color="secondary">
                Update Time {viewSnapshot?.updateTimeDisplay}
              </Text>
            </div>
            <div className={style.viewSnapshotItemBox} style={{ height: `${height - 110}px` }}>
              {viewSnapshotList?.map((viewSnapshot, index) => (
                <Item
                  key={`${viewSnapshot.id}-${index}`}
                  id={index}
                  order={index + 1}
                  name={viewSnapshot.shelfName}
                  outOfStock={viewSnapshot.outOfStockDisplay}
                  onClick={onClickSnapshotItem}
                />
              ))}
            </div>
          </Fragment>
        ) : (
          <EmptySearchResult>No data</EmptySearchResult>
        )}
      </div>
      {/* view snapshot */}
      <div className={style.viewSnapshotBox}>
        {!!viewSnapshotList?.length && (
          <Fragment>
            <div className={style.viewSnapshotTitleBox}>
              <Text element="h5">{viewSnapshot?.shelfName}</Text>
              <Text
                element="span"
                color="secondary"
              >{`Out of Stock Rate(%) : ${viewSnapshot?.outOfStockDisplay}`}</Text>
            </div>
            <div className={style.viewSnapshotTitleBox}>
              <Text element="span" color="secondary">
                Last Snapshot Time: {viewSnapshot?.updateTimeDisplay}
              </Text>
              <Button onClick={onClickDetail}>Detail</Button>
            </div>
            <img
              src={viewSnapshot?.image}
              className={style.viewSnapshotImage}
              referrerPolicy="no-referrer"
              style={{ width: `${width / 2 - 13}px`, height: `${height - 150}px` }}
            />
          </Fragment>
        )}
      </div>

      {/* modal */}
      {isOpenDetail && <Detail data={data} viewSnapshotData={viewSnapshot} onDismiss={onClickCloseDetail} />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
    padding: 0px 16px 16px 16px;
  `,
  viewSnapshotListBox: css`
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  `,
  viewSnapshotListTitleBox: css`
    display: flex;
    flex-direction: row;
    column-gap: 1em;
  `,
  viewSnapshotItemBox: css`
    overflow: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
  `,
  viewSnapshotBox: css`
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  `,
  viewSnapshotTitleBox: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,

  viewSnapshotImage: css`
    object-fit: contain;
    background: #d9d9d9;
  `,
});

export default ViewSnapshot;
