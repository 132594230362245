import React, { useState } from 'react';
import { useStyles2, TabsBar, Tab } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export interface ITab {
  label: string;
  component: any;
}

interface ITabsProps {
  defaultActive?: number;
  tabs: ITab[];
}

const Tabs: React.FC<ITabsProps> = ({ tabs = [], defaultActive = 0 }) => {
  const style = useStyles2(getStyles);
  const [tabActive, setTabActive] = useState(defaultActive);

  return (
    <div className={style.container}>
      <TabsBar>
        {tabs?.map(({ label }, index) => (
          <Tab
            label={label}
            key={`tab-${index}`}
            active={index === tabActive}
            onChangeTab={() => setTabActive(index)}
          />
        ))}
      </TabsBar>
      {tabs?.[tabActive]?.component || <React.Fragment />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
  `,
});

export default Tabs;
