import React from 'react';
import { useStyles2, Text } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

interface IItemProps {
  id: number;
  order: number;
  name: string;
  outOfStock: string;
  onClick?: (id: number) => void;
}

const Item: React.FC<IItemProps> = ({ id, order, name, outOfStock = '-', onClick }) => {
  const style = useStyles2(getStyles);

  return (
    <div className={style.container}>
      <Text textAlignment="center">{order}</Text>
      <div className={style.nameBox}>
        <Text>{name}</Text>
        <Text color="secondary">{`Out-of-Stock Rate(%) : ${outOfStock}`}</Text>
      </div>
      <a
        className={style.viewSnapshotLink}
        onClick={() => onClick && onClick(id)}
        data-testid={`ViewSnapshot-a-${order}`}
      >{`View Snapshot >`}</a>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: grid;
    grid-template-columns: 40px auto 135px;

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: 1fr;

      span:first-child {
        text-align: left;
      }
    }
  `,
  nameBox: css`
    display: flex;
    flex-direction: column;
  `,
  viewSnapshotLink: css`
    color: #3399ff;
    text-decoration: underline;

    :hover {
      color: #66b3ff;
    }
  `,
});

export default Item;
