import React, { useMemo } from 'react';
import { Table as GrafanaTable, useTheme2, useStyles2 } from '@grafana/ui';
import { toDataFrame, applyFieldOverrides, GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import useElementSize from '../../hooks/useElementSize';

interface IRow {
  no: number;
  skuCode: string;
  skuName: string;
}

interface IFieldData {
  no: number[];
  skuCode: string[];
  skuName: string[];
}

interface ITableProps {
  rows: IRow[];
}

const Table: React.FC<ITableProps> = ({ rows }) => {
  const { elementRef, size } = useElementSize();
  const theme = useTheme2();
  const style = useStyles2(getStyles);

  const tableData = useMemo(() => {
    const fieldData: IFieldData = {
      no: [],
      skuCode: [],
      skuName: [],
    };

    rows?.forEach((row) => {
      fieldData.no.push(row.no);
      fieldData.skuCode.push(row?.skuCode || '-');
      fieldData.skuName.push(row?.skuName || '-');
    });

    const frameData = toDataFrame({
      name: 'My DataFrame',
      fields: [
        {
          name: 'No',
          values: fieldData.no,
          config: { custom: { align: 'center' } },
        },
        {
          name: 'SKU Code',
          values: fieldData.skuCode,
          config: { custom: { align: 'left' } },
        },
        { name: 'SKU Name', values: fieldData.skuName, config: { custom: { align: 'left' } } },
      ],
    });

    return applyFieldOverrides({
      data: [frameData],
      theme,
      fieldConfig: { defaults: {}, overrides: [] },
      replaceVariables: (value) => value,
    })?.[0];
  }, [rows]);

  return (
    <div className={style.container} ref={elementRef}>
      <GrafanaTable width={size.width} height={size.height - 30} data={tableData} resizable />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    height: 100%;
    width: 100%;
  `,
});

export default Table;
