import React, { useMemo } from 'react';
import { useStyles2, Modal, Text, Stack } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

import { IViewSnapshotItem, PanelPageProps } from '../../types';

import Table from './table';

interface IDetailProps extends Pick<PanelPageProps, 'data'> {
  viewSnapshotData: IViewSnapshotItem;
  onDismiss?: () => void;
}

const Detail: React.FC<IDetailProps> = ({ data, viewSnapshotData, onDismiss }) => {
  const style = useStyles2(getStyles);

  const viewSnapshotDetail = useMemo(() => {
    const skuList =
      data?.series
        ?.filter((series) => {
          const labels = series?.fields?.[1]?.labels;
          return (
            series?.refId === 'view_snapshot_detail' &&
            labels?.storeID === viewSnapshotData?.storeID &&
            labels?.shelfName === viewSnapshotData?.shelfName
          );
        })
        ?.map((sku, index) => {
          const labels = sku?.fields?.[1]?.labels;
          return {
            no: index + 1,
            skuCode: labels?.skuCode || '',
            skuName: labels?.skuName || '',
          };
        }) || [];

    return {
      shelfName: viewSnapshotData?.shelfName,
      image: viewSnapshotData?.image,
      updateTime: viewSnapshotData?.updateTime,
      updateTimeDisplay: viewSnapshotData?.updateTimeDisplay,
      outOfStock: viewSnapshotData?.outOfStock,
      outOfStockDisplay: viewSnapshotData?.outOfStockDisplay,
      // skuList: [...skuList, ...skuList, ...skuList, ...skuList],
      skuList,
    };
  }, [data]);

  return (
    <Modal
      title="Shelf Details"
      isOpen={true}
      className={style.modal}
      onDismiss={onDismiss}
      contentClassName={style.contentModal}
    >
      <Stack direction={'column'} flex={1}>
        <Stack gap={2}>
          <Text>
            Shelf: <Text color="secondary">{viewSnapshotDetail?.shelfName}</Text>
          </Text>
          <Text>
            Out-of-Stock: <Text color="secondary">{viewSnapshotDetail?.outOfStockDisplay}</Text>
          </Text>
        </Stack>
        <Stack direction={'column'} wrap={'nowrap'}>
          <Text>Snap Image</Text>
          <Stack direction={'column'} alignItems={'center'} wrap={'nowrap'}>
            <img referrerPolicy="no-referrer" src={viewSnapshotDetail?.image} className={style.viewSnapshotImage} />
            <Text color="secondary">Update Time: {viewSnapshotDetail?.updateTimeDisplay}</Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'column'} flex={1}>
        <Text>Out-of-Stock SKU List:</Text>
        <Table rows={viewSnapshotDetail.skuList} />
      </Stack>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  modal: css`
    width: 80%;
    height: 70%;
  `,
  contentModal: css`
    column-gap: 2em;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
  `,
  viewSnapshotImage: css`
    width: 100%;
    height: calc(100% - 175px);
    object-fit: contain;
    background: #d9d9d9;
  `,
});

export default Detail;
