export const isValidDate = (date: Date) => {
  return !isNaN(date.getTime());
};

export const pad = (n: any) => {
  return n < 10 ? '0' + n : n;
};

export const convertToDisplayDateTimeTH = (dataTimeStr: string) => {
  let result = '';
  try {
    if (dataTimeStr) {
      const dateTimeFormat = new Date(dataTimeStr);
      if (isValidDate(dateTimeFormat)) {
        const formattedDate = dateTimeFormat.toLocaleString('th-TH', { hour12: false });
        const parts = formattedDate.split('/');
        result = pad(parts[0]) + '-' + pad(parts[1]) + '-' + parts[2];
      }
    }
  } catch (err) {
    console.log('Error convertToDisplayDateTimeTH : ', err);
  }

  return result;
};
